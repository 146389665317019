import routerOptions0 from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/build/repo/website/core/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}