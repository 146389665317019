import { default as _91slug_93hokyVcSHooMeta } from "/opt/build/repo/website/careers/pages/career-types/[slug].vue?macro=true";
import { default as indexTESjPgoU9cMeta } from "/opt/build/repo/website/careers/pages/career-types/index.vue?macro=true";
import { default as index9s1UEhr9asMeta } from "/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue?macro=true";
import { default as indexSESRtxNvcmMeta } from "/opt/build/repo/website/careers/pages/faqs/index.vue?macro=true";
import { default as indexZZFj9hasQZMeta } from "/opt/build/repo/website/careers/pages/formula/index.vue?macro=true";
import { default as indexQLwYQVSecJMeta } from "/opt/build/repo/website/careers/pages/index.vue?macro=true";
import { default as _91slug_93Euj9Eu6RSMMeta } from "/opt/build/repo/website/careers/pages/locations/[slug].vue?macro=true";
import { default as indexQoDqpnhseXMeta } from "/opt/build/repo/website/careers/pages/locations/index.vue?macro=true";
import { default as _91slug_93j23C1ebo5nMeta } from "/opt/build/repo/website/careers/pages/vacancies/[slug].vue?macro=true";
import { default as indexGJHCAKVy5dMeta } from "/opt/build/repo/website/careers/pages/vacancies/index.vue?macro=true";
import { default as component_45stubRU5sOBTecHMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubRU5sOBTecH } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "career-types-slug___en",
    path: "/career-types/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___pt_BR",
    path: "/pt_BR/tipos-de-carreira/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___da_DK",
    path: "/da_DK/karrieretyper/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___de_DE",
    path: "/de_DE/verschiedene-berufe/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___es_ES",
    path: "/es_ES/tipos-de-oportunidades-laborales/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___fr_FR",
    path: "/fr_FR/domaines-de-carriere/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___it_IT",
    path: "/it_IT/tipi-di-opportunita-professionali/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___ja_JP",
    path: "/ja_JP/career-types/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___no_NO",
    path: "/no_NO/karrieretyper/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___sv_SE",
    path: "/sv_SE/karriartyper/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types___en",
    path: "/career-types",
    meta: indexTESjPgoU9cMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___pt_BR",
    path: "/pt_BR/tipos-de-carreira",
    meta: indexTESjPgoU9cMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___da_DK",
    path: "/da_DK/karrieretyper",
    meta: indexTESjPgoU9cMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___de_DE",
    path: "/de_DE/verschiedene-berufe",
    meta: indexTESjPgoU9cMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___es_ES",
    path: "/es_ES/tipos-de-oportunidades-laborales",
    meta: indexTESjPgoU9cMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___fr_FR",
    path: "/fr_FR/domaines-de-carriere",
    meta: indexTESjPgoU9cMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___it_IT",
    path: "/it_IT/tipi-di-opportunita-professionali",
    meta: indexTESjPgoU9cMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___ja_JP",
    path: "/ja_JP/career-types",
    meta: indexTESjPgoU9cMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___no_NO",
    path: "/no_NO/karrieretyper",
    meta: indexTESjPgoU9cMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___sv_SE",
    path: "/sv_SE/karriartyper",
    meta: indexTESjPgoU9cMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "diversity-equity-inclusion___en",
    path: "/diversity-equity-inclusion",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___pt_BR",
    path: "/pt_BR/diversidade-equidade-inclusao",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___da_DK",
    path: "/da_DK/diversitet-lighed-inklusion",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___de_DE",
    path: "/de_DE/diversity-equity-inclusion",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___es_ES",
    path: "/es_ES/diversidad-equidad-inclusion",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___fr_FR",
    path: "/fr_FR/diversite-equite-inclusion",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___it_IT",
    path: "/it_IT/diversita-equita-inclusione",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___ja_JP",
    path: "/ja_JP/diversity-equity-inclusion",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___no_NO",
    path: "/no_NO/mangfold-likeverd-inkludering",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___sv_SE",
    path: "/sv_SE/mangfald-jamlikhet-inkludering",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "faqs___en",
    path: "/faqs",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___pt_BR",
    path: "/pt_BR/perguntas-frequentes",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___da_DK",
    path: "/da_DK/ofte-stillede-sporgsmal",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___de_DE",
    path: "/de_DE/faq",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___es_ES",
    path: "/es_ES/preguntas-frecuentes",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___fr_FR",
    path: "/fr_FR/faq",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___it_IT",
    path: "/it_IT/faq",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___ja_JP",
    path: "/ja_JP/faqs",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___no_NO",
    path: "/no_NO/ofte-stilte-sporsmal",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___sv_SE",
    path: "/sv_SE/vanliga-fragor",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "formula___en",
    path: "/formula",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___pt_BR",
    path: "/pt_BR/formula",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___da_DK",
    path: "/da_DK/formel",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___de_DE",
    path: "/de_DE/die-adyen-formel",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___es_ES",
    path: "/es_ES/formula",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___fr_FR",
    path: "/fr_FR/formula",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___it_IT",
    path: "/it_IT/formula",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___ja_JP",
    path: "/ja_JP/formula",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___no_NO",
    path: "/no_NO/formel",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___sv_SE",
    path: "/sv_SE/formeln",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___pt_BR",
    path: "/pt_BR",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___da_DK",
    path: "/da_DK",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___de_DE",
    path: "/de_DE",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___es_ES",
    path: "/es_ES",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___fr_FR",
    path: "/fr_FR",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___it_IT",
    path: "/it_IT",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___ja_JP",
    path: "/ja_JP",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___no_NO",
    path: "/no_NO",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___sv_SE",
    path: "/sv_SE",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "locations-slug___en",
    path: "/locations/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___pt_BR",
    path: "/pt_BR/onde-estamos/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___da_DK",
    path: "/da_DK/placeringer/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___de_DE",
    path: "/de_DE/standorte/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___es_ES",
    path: "/es_ES/ubicaciones/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___fr_FR",
    path: "/fr_FR/bureaux/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___it_IT",
    path: "/it_IT/localita/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___ja_JP",
    path: "/ja_JP/locations/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___no_NO",
    path: "/no_NO/steder/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___sv_SE",
    path: "/sv_SE/platser/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations___en",
    path: "/locations",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___pt_BR",
    path: "/pt_BR/onde-estamos",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___da_DK",
    path: "/da_DK/placeringer",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___de_DE",
    path: "/de_DE/standorte",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___es_ES",
    path: "/es_ES/ubicaciones",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___fr_FR",
    path: "/fr_FR/bureaux",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___it_IT",
    path: "/it_IT/localita",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___ja_JP",
    path: "/ja_JP/locations",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___no_NO",
    path: "/no_NO/steder",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___sv_SE",
    path: "/sv_SE/platser",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "vacancies-slug___en",
    path: "/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___pt_BR",
    path: "/pt_BR/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___da_DK",
    path: "/da_DK/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___de_DE",
    path: "/de_DE/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___es_ES",
    path: "/es_ES/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___fr_FR",
    path: "/fr_FR/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___it_IT",
    path: "/it_IT/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___ja_JP",
    path: "/ja_JP/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___no_NO",
    path: "/no_NO/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___sv_SE",
    path: "/sv_SE/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies___en",
    path: "/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___pt_BR",
    path: "/pt_BR/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___da_DK",
    path: "/da_DK/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___de_DE",
    path: "/de_DE/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___es_ES",
    path: "/es_ES/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___fr_FR",
    path: "/fr_FR/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___it_IT",
    path: "/it_IT/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___ja_JP",
    path: "/ja_JP/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___no_NO",
    path: "/no_NO/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___sv_SE",
    path: "/sv_SE/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/how-we-hire",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubRU5sOBTecH
  }
]